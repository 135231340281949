import "./jquery.onepage-scroll.js";

$(document).ready(function () {


//-------------------one-page ckroll

	var isMobile = {
	    Android: function() {
	        return navigator.userAgent.match(/Android/i);
	    },
	    BlackBerry: function() {
	        return navigator.userAgent.match(/BlackBerry/i);
	    },
	    iOS: function() {
	        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	    },
	    Opera: function() {
	        return navigator.userAgent.match(/Opera Mini/i);
	    },
	    Windows: function() {
	        return navigator.userAgent.match(/IEMobile/i);
	    }, 
	    any: function() {
	        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
	    }
	};

	function ActiveteScroll (){
		$(".maincontent").onepage_scroll({
		   sectionContainer: "section",     // sectionContainer accepts any kind of selector in case you don't want to use section
		   easing: "ease",                  // Easing options accepts the CSS3 easing animation such "ease", "linear", "ease-in",
		                                    // "ease-out", "ease-in-out", or even cubic bezier value such as "cubic-bezier(0.175, 0.885, 0.420, 1.310)"
		   animationTime: 1000,             // AnimationTime let you define how long each section takes to animate
		   pagination: true,                // You can either show or hide the pagination. Toggle true for show, false for hide.
		   updateURL: false,                // Toggle this true if you want the URL to be updated automatically when the user scroll to each page.
		   beforeMove: function(index) {
		   		$('ul.onepage-pagination li:nth-child('+index+')').siblings('li').removeClass('active');
			   	var paginationList = $('ul.onepage-pagination');
			   	paginationList.removeClass('pagination_green');
			   	$('ul.onepage-pagination li.active').removeClass('active');
		   },  // This option accepts a callback function. The function will be called before the page moves.
		   afterMove: function(index) {
		   	if(index !== 1) {
		   			var first = document.querySelector('ul.onepage-pagination li');
		   			first.classList.remove('active');
		   		}
		   	var paginationList = $('ul.onepage-pagination');
		   	var dots = paginationList.find('li');
		   	if (index == 1 || index == 3 || index == 5) {
		   		// paginationList.addClass('pagination_light');
		   		dots[index-1].classList.add('active');
		   	} else {
		   		paginationList.addClass('pagination_green');
		   		dots[index-1].classList.add('active');
		   	}
		   },   // This option accepts a callback function. The function will be called after the page moves.
		   loop: false,                     // You can have the page loop back to the top/bottom when the user navigates at up/down on the first/last page.
		   keyboard: true,                  // You can activate the keyboard controls
		   responsiveFallback: false,        // You can fallback to normal page scroll by defining the width of the browser in which
		                                    // you want the responsive fallback to be triggered. For example, set this to 600 and whenever
		                                    // the browser's width is less than 600, the fallback will kick in.
		   direction: "vertical"            // You can now define the direction of the One Page Scroll animation. Options available are "vertical" and "horizontal". The default value is "vertical".  
		});
	};

	function HideShowFunc() {
		$(".distributive__item.distributive-active").find(".download__text").show();
		$(".distributive__item.distributive-active").siblings().find('.download__text').hide();

		var downloadItems = $(".distributive__item");
		downloadItems.hover(function(e) {
			var $this = $(this);
			var eventParent = $this.closest('.distributive__item');
			var OtherItems = eventParent.siblings('.distributive__item');
			if(!eventParent.hasClass('distributive-active')) {
				OtherItems.removeClass('distributive-active');
				OtherItems.find('.download__text').hide(300);
				eventParent.addClass('distributive-active');
				eventParent.find(".download__text").show(300);
			}
		}, function() {});
	};

	if(isMobile.any() || $(window).width() <= '1100' || $(window).width() >= '1400' && $(window).height() <= '650'){ 
		if( $(window).width() > '1100') {
			HideShowFunc();
		}

		var wrapper = $(".wrapper");
		var html = $("html");
		var body = $("body");
		wrapper.addClass('mobile');
		html.css("overflow", "auto");
		body.css("overflow", "auto");

		if ($(window).height() <= '650') {
			window.addEventListener("orientationchange", function() {
		    	$('footer.footer').css("position","static");
		    }, false);
		}

	} else {
		ActiveteScroll();
		HideShowFunc();

		var dots = $('ul.onepage-pagination li');
		var activated = false;
		for (let i=0; i<dots.length; i++){
			if (dots[i].classList.contains('active')) {

				activated = true;
			}
		}

		if(!activated) {
			dots[0].classList.add('active')
		};
	}



	
	if (lang == 3) {
		$('.sent-text').text('Сообщение было отправлено');
	}


	var linkToCrypto = '151mr3QEwdz77FqcX8gEXbpA4SBhrw8hiL';
	var helpButton = document.getElementsByClassName("help-project")[0];
	helpButton.addEventListener('click', function(){
		navigator.clipboard.writeText(linkToCrypto)
		  .then(() => {
		    $('.modal__copy').addClass('active');
		  })
		  .catch(err => {
		    return;
		  });
	});


	$('.social__item_rss .social__link').on('click', function(e){
		e.preventDefault();
		var modalContact = $('.modal-contact-over');
		if(!modalContact.hasClass('active')) {
			modalContact.addClass('active');
		}
	});
	

	$('#copy-close').on('click', function(){
		$('.modal__copy').removeClass('active');
	});

	function CloseModalMail() {
		$('.modal-contact-over').removeClass('active');
		if ($('.modal-contact-inner').hasClass('sent')) {
			$('.modal-contact-inner').removeClass('sent');
		}
	}

	
	$('#close-mail').on('click', function(){
		var modalContact = $('.modal-contact-over');
		CloseModalMail();
	});

	$('.modal-contact-over').on('click', function(e){
		var modalContact = document.getElementsByClassName("modal-contact-over")[0];
		var modalContactBody = document.getElementsByClassName("modal-contact-inner")[0];
		if(modalContact.classList.contains('active') && !modalContactBody.contains(e.target)){
			CloseModalMail();
		}
	});


	var langList = $('.lang__list');
	$('.active-lang').on('click', function(e){
		e.preventDefault();
		langList.toggleClass('active');

		$(window).on('click', function(e){
			let langList = document.getElementsByClassName('lang__list')[0];
			var $this = $(this);
			if (e.target !== langList && langList.classList.contains('active') && !langList.contains(e.target)) {
				langList.classList.remove('active');
			}
		})
	})

	$('.send-row').on('click', function(e){
		e.preventDefault();
		var loginInput = $('.row__input[name="name"]'),
			emailInput = $('.row__input[name="email"]'),
			textInput = $('.row__input[name="text"]');

		var login = loginInput.val();
		var email = emailInput.val();
		var text = textInput.val();

		var reg = email.match(/^[0-9a-z-\.]+\@[0-9a-z-]{2,}\.[a-z]{2,}$/i);

		$('.row__input[name="email"]').on('input', function(){
			if($('.row__input[name="email"]').hasClass('errored')) {
				$(this).removeClass('errored');
			}
		})

		$('.row__input[name="text"]').on('input', function(){
			if($('.row__input[name="text"]').hasClass('errored')) {
				$(this).removeClass('errored');
			}
		})

		if(!reg) {
			$('.row__input[name="email"]').addClass('errored');
			return;
		}

		if (!text) {
			$('.row__input[name="text"]').addClass('errored');
			return;
		}


		let obj = {
			'name': login,
			'email': email,
			'text': text
		};
		let JSONobj = JSON.stringify(obj);

		fetch('mail.php', {
			method: 'POST',
			headers: new Headers({
				'Content-Type' : 'application/json'
			}),
			body: JSONobj
		})
		.then(response =>{
			return response.json();
		})
		.then(resp => {
			if (resp == true){
				loginInput.val('');
				emailInput.val('');
				textInput.val('');
				$('.modal-contact-inner').addClass('sent');
				setTimeout(function(){
					CloseModalMail();
				}, 3000);
			}
		})
		.catch(error =>{
			return;
		})
	})

	$('.footer .safe-icon').on('click', function(e){
		if (isMobile.any() || $(window).width() <= '1100'){
			$('html, body').animate({scrollTop: 0},500);
		    return false;
		} else {
			$(".maincontent").moveTo(1);
		}
	})

	

	if (localStorage.getItem('count')) {
		var count = String(localStorage.getItem('count'));
	} else {
		var count = '11503';
	}

	if (localStorage.getItem('count2')) {
		var count2 = String(localStorage.getItem('count2'));
	} else {
		var count2 = '26304';
	}


	function rand(min, max){
		return (max-min)*Math.random()+min
	}

	var counter = $('.counter__list');
	var Tenthousands = counter.eq(0).find('.counter__digit').eq(0),
		thousands = counter.eq(0).find('.counter__digit').eq(1),
		hundreds = counter.eq(0).find('.counter__digit').eq(2),
		dec =  counter.eq(0).find('.counter__digit').eq(3),
		one =  counter.eq(0).find('.counter__digit').eq(4);

	var Tenthousands2 = counter.eq(1).find('.counter__digit').eq(0),
		thousands2 = counter.eq(1).find('.counter__digit').eq(1),
		hundreds2 = counter.eq(1).find('.counter__digit').eq(2),
		dec2 =  counter.eq(1).find('.counter__digit').eq(3),
		one2 =  counter.eq(1).find('.counter__digit').eq(4);

		function firstCountPlus(){
			let newNumber = Number(count)+1;
			count = String(newNumber);
			localStorage.setItem('count', count);
			Tenthousands.html(count[0]);
			thousands.html(count[1]);
			hundreds.html(count[2]);
			dec.html(count[3]);
			one.html(count[4]);	
		}

		function firstCountPlus2(){
			let newNumber2 = Number(count2)+1;
			count2 = String(newNumber2);
			localStorage.setItem('count2', count2);
			Tenthousands2.html(count2[0]);
			thousands2.html(count2[1]);
			hundreds2.html(count2[2]);
			dec2.html(count2[3]);
			one2.html(count2[4]);
		}
		
		firstCountPlus();
		firstCountPlus2();

		

	var x = setInterval(function() {
		firstCountPlus();
	}, rand(10000, 30000));

	var y = setInterval(function() {
		firstCountPlus2();
	}, rand(10000, 30000));


	

	

});